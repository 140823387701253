import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { HeroStyles } from '../styles/GlobalStyles'
import SEO from '../components/SEO'
import BackgroundImage from '../components/BackgroundImage'
import Image from '../components/Image'
import bemWordmark from '../assets/logo/bem-wordmark.png'
import { getImageByName } from '../util/util'

const ToursStyles = styled.div`
  padding: var(--spacing);
`

const TourStyles = styled.article`
  margin-bottom: 3rem;
  max-width: 700px;

  h2 {
    text-transform: uppercase;
    font-weight: bold;
    margin: var(--spacing) 0 1rem 0;
  }

  .details {
    margin: 0.5rem 0;

    span {
      font-weight: bold;
    }
  }
`

export default function ToursPage({ data }) {
  // Hero image
  const toursImage = data?.file?.childImageSharp?.gatsbyImageData

  // Tour info
  const toursData = data?.allGoogleSpreadsheetBemTours?.nodes

  // Tour images
  // const tourImagesData = data?.allDropboxFolder?.group?.[0]?.nodes?.[0]?.dropboxImage
  const tourImagesData = data?.allFile?.edges

  return (
    <>
      <SEO title="Boat Tours" />
      <BackgroundImage imageData={toursImage} underHeader fullHeight>
        <HeroStyles>
          <div>
            <img className="logo" src={bemWordmark} alt="Bird's Eye Maine" />
            <h1>Boat Tours</h1>
            <p>Private boat charters of Casco Bay</p>
          </div>
        </HeroStyles>
      </BackgroundImage>
      <ToursStyles>
        {toursData?.map((tour) => {
          const tourImageData = getImageByName(tourImagesData, tour?.imageName)

          return (
            <TourStyles key={tour?.id}>
              <h2>{tour?.name}</h2>
              {tourImageData ? <Image imageData={tourImageData} /> : null}
              <p>{tour?.description}</p>
              <div className="details">
                <span>Duration:</span> {tour?.duration}
              </div>
              <div className="details">
                <span>Pricing:</span> {tour?.pricing}
              </div>
              <div className="details">
                <span>Capacity:</span> {tour?.capacity} people
              </div>
            </TourStyles>
          )
        })}
      </ToursStyles>
    </>
  )
}

export const query = graphql`
  {
    file(sourceInstanceName: { eq: "background" }, name: { eq: "tours" }) {
      name
      childImageSharp {
        id
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90, formats: [AUTO, WEBP])
      }
    }
    allFile(filter: { extension: { regex: "/(jpeg|jpg)/" }, sourceInstanceName: { eq: "tours" } }) {
      edges {
        node {
          name
          childImageSharp {
            id
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, height: 500, quality: 90, formats: [AUTO, WEBP])
          }
        }
      }
    }
    allGoogleSpreadsheetBemTours {
      nodes {
        id
        name
        pricing
        duration
        capacity
        description
        imageName
      }
    }
    # Temporarily skip the dropbox integration, it's not working
    # allDropboxFolder(filter: { name: { regex: "/Tours/" } }) {
    #   group(field: name) {
    #     nodes {
    #       dropboxImage {
    #         id
    #         name
    #         localFile {
    #           name
    #           id
    #           childImageSharp {
    #             gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90, formats: [AUTO, WEBP])
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
  }
`
