export const stringsAreEqual = (str1 = '', str2 = '') =>
  str1?.trim()?.toLowerCase() === str2?.trim()?.toLowerCase() || ''

export const getImageByName = (imageData = {}, imageName = '') => {
  const imageByName = imageData.find((image) => stringsAreEqual(image?.node?.name, imageName))
  return imageByName?.node?.childImageSharp?.gatsbyImageData
}

export const getRandomTestimonial = (testimonialsData = {}) =>
  testimonialsData[Math.floor(Math.random() * testimonialsData.length)]
